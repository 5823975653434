// components
import {
  Button,
  Table,
  TableColumn,
  Dialog,
  Input,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Form,
  FormItem,
} from 'element-ui';
import searchInput from '@/components/searchInput';
import backStageHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
// api
import { getSchoolAuthorityPage, getSchoolAuthorityDetail, saveResourceAuthority } from '@/api/back-stage';

export default {
  name: 'totalAuthority',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    searchInput,
    backStageHeader,
    qlPagination,
  },
  data() {
    return {
      title: '',
      schoolKeyword: '',

      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      visible: false,
      loading: false,
      form: {
        id: '',
        schoolId: '',
        schoolKey: '',
        schoolName: '',
        thirdResource: [],
        thirdApplication: [],
        printPlan: '',
      },
      thirdResourceList: [], // 所有可选资源权限
      thirdApplicationList: [], // 所有可选应用权限
      printPlanList: [], // 所有可选铺码方案

      oldPrintPlan: '', // 原铺码方案
    };
  },
  computed: {
    // 当选中 “智慧课堂” 时，应用权限才可以选择 “金牌助教”、“一键关机”、“智慧练习”
    // 当选中 智慧练习（标准版) 时，应用权限才可以选择 “智慧练习-教辅作业”
    thirdApplicationExceptList() {
      if (this.thirdApplicationList.length) {
        const smartClassId = 5; // 智慧课堂id
        const exerciseId = 3; // 智慧练习（标准版)id
        let list = [];
        if (!this.form.thirdApplication.includes(smartClassId)) {
          // id: 1-一键关机 2-金牌助教 3-智慧练习（标准版) 4-青鹿管家 5-智慧课堂 6-智慧练习-预制作业
          list = this.thirdApplicationList.filter((app) => {
            if ([1, 2, 3, 6].includes(app.id)) {
              // 删除选中
              const appIndex = this.form.thirdApplication.indexOf(app.id);
              appIndex > -1 && this.form.thirdApplication.splice(appIndex, 1);
              return false;
            }
            return true;
          });
        } else if (!this.form.thirdApplication.includes(exerciseId)) {
          list = this.thirdApplicationList.filter((app) => {
            if ([6].includes(app.id)) {
              // 删除选中
              const appIndex = this.form.thirdApplication.indexOf(app.id);
              appIndex > -1 && this.form.thirdApplication.splice(appIndex, 1);
              return false;
            }
            return true;
          });
        } else {
          list = this.thirdApplicationList;
        }
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    // 根据名称搜索
    searchData() {
      this.getDataList(true);
    },
    // 加载表格数据
    getDataList(reset = false) {
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      getSchoolAuthorityPage({
        pageIndex,
        pageSize,
        schoolNameFuzzy: this.schoolKeyword,
      }).then((res) => {
        if (res.result) {
          this.listData.total = res.result.recordCount;
          res.result.data.forEach((item) => {
            item.schoolToThirdResourceStr = item.schoolToThirdResources.map((rs) => rs.serverName).join(' | ');
            item.schoolToThirdApplicationStr = item.schoolToThirdApplications.map((rs) => rs.serverName).join(' | ');
          });
          this.listData.list = res.result.data;
        } else {
          this.listData.list = [];
          this.listData.total = 0;
        }
      });
    },
    // 获取学校权限详情
    getDetail(id) {
      getSchoolAuthorityDetail({ id }).then((res) => {
        let {
          id,
          schoolId,
          schoolKey,
          schoolName,
          schoolToThirdApplications,
          schoolToThirdResources,
          schoolPrintPlan,
          thirdApplications,
          thirdResources,
          printPlan,
        } = res.result.school;

        this.thirdResourceList = thirdResources || [];
        this.thirdApplicationList = thirdApplications || [];
        this.printPlanList = (printPlan || []).map((item) => {
          item['penType'] = item.deviceList.map((pen) => pen.deviceName).join('、');
          return item;
        });

        this.form = {
          id,
          schoolId,
          schoolKey,
          schoolName,
          thirdResource: (schoolToThirdResources || []).map((item) => item.serverId),
          thirdApplication: (schoolToThirdApplications || []).map((item) => item.serverId),
          printPlan: (schoolPrintPlan || { planCode: '' }).planCode || '',
        };
        this.oldPrintPlan = (schoolPrintPlan || { planCode: '' }).planCode || '';
      });
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 编辑
    handleEdit(row) {
      this.visible = true;
      this.getDetail(row.schoolId);
    },
    // 点击保存按钮进行校验
    handleSaveClick() {
      // 判断铺码方案是否改变
      if (this.oldPrintPlan !== this.form.printPlan) {
        this.$msgbox
          .confirm('切换铺码方案会导致原来下载的答题纸无法再使用，确认保存吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            this.handleSave();
          })
          .catch(() => {});
      } else {
        this.handleSave();
      }
    },
    // 保存
    handleSave() {
      this.loading = true;
      let { schoolId, thirdResource, thirdApplication, printPlan } = this.form;
      let schoolToThirdResources = this.thirdResourceList.filter((item) => thirdResource.includes(item.id));
      let schoolToThirdApplications = this.thirdApplicationList.filter((item) => thirdApplication.includes(item.id));
      let schoolPrintPlan = this.printPlanList.filter((item) => printPlan.includes(item.plancode));

      let params = {
        schoolId,
        schoolToThirdResources: JSON.stringify(
          schoolToThirdResources.map((item) => ({
            serverId: item.id,
            serverName: item.serverName,
          }))
        ),
        schoolToThirdApplications: JSON.stringify(
          schoolToThirdApplications.map((item) => ({
            serverId: item.id,
            serverName: item.serverName,
          }))
        ),
        printplanRSchool: JSON.stringify(
          schoolPrintPlan.map((item) => ({
            planCode: item.plancode || '',
            planName: item.planname || '',
            schoolId,
          }))
        ),
      };
      saveResourceAuthority(params)
        .then(() => {
          this.$message.success('保存成功！');
          this.visible = false;
          this.getDataList();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 表单重置
    resetForm() {
      this.$refs.form.resetFields();
      this.form.id = '';
      this.form.schoolId = '';
    },
  },
  created() {
    this.title = this.$route.meta.title;
  },
  mounted() {
    this.getDataList();
  },
};
